@import './jre-variables.css';

body {
    font-family: var(--base-font);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--header-font);
    color: var(--text-gray);
}
.h1,
h1 {
    font-size: 34px;
    text-transform: uppercase;
}
.h2,
h2 {
    font-size: 28px;
    text-transform: uppercase;
}
.h3,
h3 {
    z-index: 1;
    font-size: 22px;
    margin-bottom: 4px;
}
.h5,
h5 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 1rem;
    margin-bottom: 2rem;
    position: relative;
    z-index: 1;
    font-size: 16px;
}
.h5:after,
h5:after {
    content: "";
    background-color: var(--pitsco-active-blue);
    width: 22px;
    height: 6px;
    border-radius: 2px;
    display: block;
    position: absolute;
    bottom: -3px;
    left: 0px;
}

body .jre-player {
	width: 100%;
	height: 100%;
	max-width: 100vw;
	max-height: 100vh;
	position: fixed;
	margin: 0 0 0 0;
	padding: 0 0 0 0;
	font-family: var(--base-font);
	-webkit-font-smoothing: antialiased;
	background-color: var(--pitsco-black);
	display: block;
	line-height: 1.25;
	letter-spacing: -0.2px;
	word-spacing: -0.5px;
}
.jre-player {

	* {
		box-sizing: border-box;
		line-height: initial;
		text-decoration: none;
	}

	.book-content.expanded {
		width: 2000px !important;
		overflow: scroll !important;
	}

	.playerouter {
		background: var(--pitsco-blue-gray);
		min-height: 300px;
		z-index: 10001;
		float: left;
		top: 50px;
		position: absolute;
		height: 100%;
		max-height: calc(100vh - 90px);
		overflow-x: hidden;
		overflow-y: visible;
		z-index: 1000;
	}

	.plleft {
		background-color: var(--pitsco-blue-gray);
		height: 100%;
		display: inline-block;
		/*max-height: calc(100vh - 105px);*/
		/*overflow-y: visible;*/
		/*overflow-x: hidden;*/
		width: 100%;

        .extended-content-sidebar *,
		.extended-content-viewer * {
            text-decoration: initial;
            padding: 10px;
        }
		.extended-content-sidebar {
			min-width: 275px;
			width: 25%;
			height: 100%;
			position: relative;
			float: left;
			background: var(--pitsco-light-gray);
			border-right: 1px solid var(--text-muted);
			box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.32);
			display: inline-block;
			max-height: calc(100vh - 105px);
			overflow: hidden;
			z-index: 1002;
			left: 0;
			top: 0;
			text-align: left;
			font-size: 15px;
			font-size: 15.5px;
			padding: 18px 3px 32px 4px;
			list-style: none outside none;
			margin: 0;
			word-wrap: break-word;
			cursor: auto;
            text-decoration: initial;



			.hideLinkInNewWindowCheckbox{
				.rdw-link-modal{
                    /*left: -90px; Naren */
					height: auto;
				}
			}

			.extended-form {
				background-color: var(--color-white);
				padding: 20px 3px 30px 4px;
				display: block;
				width: 96%;
				margin: 0 auto;
				min-height: 20%;
				font-family: var(--secondary-font);
				border: 1px solid #D6D7D8;
				box-shadow: 0 1px 3px rgba(50,65,80,0.08);
				border-radius: 6px;
			}
			.description {
				background-color: var(--color-white);
				padding: 20px 4px 30px 7px;
				display: block;
				width: 95%;
				margin: 0 auto;
				min-height: 20%;
				border: 1px solid var(--text-muted-light);
				border-radius: 9px;
				box-shadow: 0 2px 6px 1px rgba(0,0,0,0.12);
				font-family: var(--secondary-font);

				.alert {
					padding-left: 75px;
					background-color: var(--pitsco-light-gray);
					.info-text {
						font-size: 13px !important;
						font-weight: 600 !important;
					}
				}

				.extended-content-viewer {
					padding: 12px 2px 20px 4px;
					font-size: 14px; /*IE Fallback*/
					font-size: 14.5px;
					letter-spacing: -0.4px;
					word-spacing: -0.8px;
					display: block;
					width: 100%;
					font-family: var(--secondary-font);
				}
			}

			.h5, h5 {
				font-size: 16px;
				font-size: 16.5px;
				padding: 0 3px 9px 2px !important;
				margin: 0 4px 0 10px !important;
				text-transform: uppercase;
				letter-spacing: -0.5px;
				word-spacing: -0.9px;
			}

			.teacher-btn-container {
				position: absolute;
				bottom: 0;
				display: block;
				width: 100%;
				padding: 10px 20px 10px 10px;
			}
		}

		.book-content {

			width: 100%;
			display: block;
			height: 100%;
			a {
				color: #33A7FE;
				text-shadow: 0 1px 2px rgba(50, 140, 245, .65);
				text-shadow: 0 0.5px 2px rgba(50, 140, 245, .65);
				stroke: 0.1px rgba(250, 235, 10, 0.85);
				-webkit-text-stroke: 0.1px rgba(250, 230, 10, .8);
				-webkit-text-stroke: 0.07px rgba(250, 228, 10, .8);
				background-color: rgba(10,3,0,0.03);
				padding: 1px 2px 0 2px;
				letter-spacing: 0.3px;
				word-spacing: 0.5px;
				cursor:pointer;
				transition-duration: 0.3s;
				text-decoration: none !important;
			}
			a:hover {
				text-shadow: 0px 0.5px 4px rgba(70, 160, 255, .6);
				stroke: 0.1px rgba(250, 225, 10, .8);
				-webkit-text-stroke: 0.1px rgba(250, 228, 10, .5);
				background-color: rgba(15,5,0,0.04);
				color: #43B3Ff;
				text-decoration: none !important;
			}
		}
	}

	.node {
		position: absolute;
	}

	.book-content-container {
		background-size: cover;
		-webkit-transition: all;
		-ms-transition: all;
		margin: 0 auto;
		position: relative;
		overflow: hidden;
		/*max-height: calc(100vh - 105px);*/
		/*overflow-y: visible;*/
    /* border: 1px solid #1b2655; */
    /* margin: 10px; */

    box-shadow: 0 4px 16px 0 rgb(0 0 0 / 10%);

      height: 98vh !important;
      min-height: 768px;
      background-repeat: no-repeat !important;
	}

	.selected-mc-answer {
		color: var(--selected-txt-color);
	}

	.node.feedback-item {
		left: 0;
		bottom: 0;
		background-color: var(--notification-bg-color);
		font-weight: bold;
		word-break: normal;
		width: 100%;
		padding: 5px;
	}

	.node.answer-node {
		word-break: normal;
		cursor: pointer;
	}

	.node.button-node {
		cursor: pointer;
		border: 0;
		outline: 0;
		overflow: hidden;
	}

	.te-node .button-node, .te-node .textarea-node {
		z-index: 1;
	}

	.node.ce-button {
		z-index: 1;
		outline: 0;
		border: 0;
		/* This overflow hidden currently breaks https://vnextdev.pitsco.com/view/modules/154/sections/774/pages/24
		(Energy, power and mechanics, session 2, page 24) for which we have no sane solution atm. */
		overflow: hidden;
	}

	.overlay-mask {
		position: absolute;
		top: 0;
		bottom: 0;
		/* This z-index value should be the highest relative to other values */
		z-index: 100;
		width: 100%;
		height: 100%;
		
	}

	.slideshow-node {
		/* slideshow-node should appear over the rest of the page underneath */
		position: absolute;
		z-index: 98;
	}

	.animated-image-node {
		z-index: 1;
	}

	.visibility-hidden {
		visibility: hidden;
	}

	.jre-textarea {
		/* word-break here overrides book-content's word-break, which was stopping word wrapping */
		word-break: normal;
		resize: none;
		outline: 0;
		background: transparent;
		overflow: hidden;
	}

	.caption {
		border: var(--caption-border-width) var(--caption-border-style) var(--caption-border-color);
		background-color: var(--caption-bg-color);
		border-radius: var(--caption-border-radius);
		padding: var(--caption-padding);
		word-break: normal;
		color: #001;
	}

	.caption-inner-container {
		margin: var(--caption-container-margin);
    background-color: #FFFFFF;
    padding: 5px;
	}

	.caption-outer-container {
		bottom: 0;
		width: 100%;
		left: 0;
		z-index: 101;
		opacity: 0.94;
	}

	.caption-outer-video-container {
		top: 0;
		width: 100%;
		left: 0;
		z-index: 101;
		opacity: 0.88;
		.caption-inner-container {
			margin: 4px 8px 6px 8px;
		}
	}

	.dd-node {
		cursor: pointer;
	}

	.range-field input {
		border-width: var(--te-border-width);
	}

	.match-field input {
		border-width: var(--te-border-width);
	}

	.text-field input {
		border-width: var(--te-border-width);
	}

	.text-area input {
		border-width: var(--te-border-width);
	}

	.flex-center-items {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.jre-text-node {
		/* Setting this to a higher value may overflow text outside an image container on some slides. So the point
		of this is to restrict overflow outside a container which may be a background image the text is placed on top of. */
		/*font-size: var(--text-font-size);*/
		z-index: 1;
		word-break: normal;
	}

	.jre-dd-node {
		z-index: 2;
	}

	.jre h2,
	.caption-inner-container .caption,
	textarea {
		color: #001;
	}

	video {
		z-index: 2;
	}

	p{
		font-size: inherit;
	}

	.hidden {
		display: none;
	}

	.book-report-container {
		padding: 50px 55px;
		font-family: 'Times New Roman', Arial, sans-serif;
	}

	.book-report-container h1 {
		font-weight: bold;
	}

	.book-content-container hr {
		border: 0;
		height: 2px;
		background: #000;
	}

	.mc-node-container .question-node {
		text-align: left;
    /* color: #000000 !important; */
	}

  /* .answer-node{
    color: #000000 !important;
  } */

    .assessment-question-node-container {
		color: var(--color-black);
    }

    /* Disabling the fullscreen button currently only works with webkit/Chrome. */
	video::-webkit-media-controls-fullscreen-button {
		display: none;
	}
	button, input, select, textarea {
		padding: 0;
		margin: 0;
	}

	.te-node .match-field {
		z-index: 2;
	}

	.action-button-node button {
		/* Printing often takes place after a te-node interaction, so we need to show the button above the overlay  */
		z-index: 101;
	}

	.fake-button {
		border-radius: 20px;
		width: 212px;
		height: 100px;
		color: #000;
		font-weight: bold;
		font-size: 30px;
		padding: 30px 15px;
		border: 4px solid #333;
		text-align: center;
		background: rgb(83,83,83); /* Old browsers */
		background: -moz-linear-gradient(top, rgb(83,83,83) 0%, rgb(174,174,174) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top, rgb(83,83,83) 0%,rgb(174,174,174) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom, rgb(83,83,83) 0%,rgb(174,174,174) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#535353', endColorstr='#aeaeae',GradientType=0 ); /* IE6-9 */
	}

	.dd-target-overlay-div {
		position: relative;
		float: left;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {
    @-moz-document url-prefix() {
        .book-content-container {
            transform: scale(0.7) !important;
            transform-origin: 0 0 !important;
                margin: 0 0px !important;
        }
    }
		.book-content-container
		{
				zoom:73% !important;
		}
}

@media screen and (max-width: 1366) , screen and (max-height: 768px) {
	.book-content-container
	{
			zoom:73% !important;
			height: 768px;
    	/* width: 1025px;  this old*/
      width: 100% !important;
	}
}

@media (min-width: 1025px) and (max-width: 1223px) {
    @-moz-document url-prefix() {
        .book-content-container {
            transform: scale(0.8) !important;
            transform-origin: 0 0 !important;
                margin: 0 0px !important;
        }
    }
		.book-content-container
		{
				zoom:76% !important;
		}
}

@media (min-width: 1224px) and (max-width: 1400px)
 {
    @-moz-document url-prefix() {
        .book-content-container {
            transform: scale(0.7) !important;
            transform-origin: 0 0 !important;
                margin: 0 0px !important;
        }
    }
		.book-content-container
		{
				zoom: 72%!important;
		}
}

@media (min-width: 1401px) and (max-width: 1600px)
 {
    @-moz-document url-prefix() {
        .book-content-container {
            transform: scale(0.8) !important;
            transform-origin: 0 0 !important;
                margin: 0 0px !important;
        }
    }
		.book-content-container
		{
			zoom:86% !important;
        	/* zoom:100% !important; */
		}
}

@media (min-width: 1601px) and (max-width: 1800px)
 {
    @-moz-document url-prefix() {
        .book-content-container {
            transform: scale(0.9) !important;
            transform-origin: 0 0 !important;
                margin: 0 0px !important;
        }
    }
		.book-content-container
		{
				zoom:90% !important;
		}
}



@media (min-width:1824px) {
    @-moz-document url-prefix() {
        .book-content-container {
            transform: scale(0.9);
            transform-origin: 0 0;
                margin: 0 0px !important;
    }
}
.book-content-container
{
    zoom:110% !important;
}
}
/*
.jre-player .plleft .book-content{
  height: calc(100% - 70px) !important;
}

.jre-player.book-content{
  height: calc(100% - 70px) !important;
} */
/* - for loading show */
/* - for loading show */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 12px solid #f3f3f3;
  border-top: 8px solid #1b2655;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: spin 1s linear infinite;
  position: absolute;
  text-align: center;
  top:40%;
  left: 34%;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
 0% {
   transform: rotate(0deg);
 }
 100% {
   transform: rotate(360deg);
 }
}
