@import './jre-variables.css';

.jre-player {

    .jre-navbar {
        z-index: 1006;
        position: fixed;
        top: 0;
        width: 100%;
        height: 45px;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8d730f', endColorstr='#f7ed17', GradientType=0);
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(top, #8d730f 1%, #f7ed17 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #8d730f), color-stop(100%, #f7ed17));
        background: -webkit-linear-gradient(top, #8d730f 1%, #f7ed17 100%);
        background: -o-linear-gradient(top, #8d730f 1%, #f7ed17 100%);
        background: -ms-linear-gradient(top, #8d730f 1%, #f7ed17 100%);
        background: linear-gradient(to bottom, #8d730f 1%, #f7ed17 100%);
        z-index: 10005;
        background-color: var(--pitsco-active-blue);
        -webkit-box-shadow: 0 2px 6px 1px rgba(0, 3, 10, .32);
        -moz-box-shadow: 0 2px 6px 1px rgba(0, 3, 10, .32);
        box-shadow: 0 2px 6px 1px rgba(0, 3, 10, .32);
    }
    .viewer-navbar {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 10005;
      /* background-color: var(--pitsco-active-blue); */
      background-color:#FFFFFF;
      /* background-color: #1b2655; */
      /* -webkit-box-shadow: 0 1px 3px 1px rgba(0, 3, 10, .24);
      -moz-box-shadow: 0 1px 3px 1px rgba(0, 3, 10, .24);
      box-shadow: 0 1px 3px 1px rgba(0, 3, 10, .24); */
      margin-right: 0px !important;
     margin-left: 0px !important;
     color: #1b2655;
     padding-top: 8px;
    }

    .viewer-navbar-title {
        font-size: 20px;
        padding: 0 2%;
        line-height: 1.6;
        letter-spacing: -0.4px;
        font-weight: 600;
        transition-duration: 0.3s;
        /* text-shadow: 0 0 1px rgba(90, 80, 80, .25), 0 1px 0 rgba(255, 255, 255, 0.3); */
        min-width: 15%;
        padding-top: 5px;
        a, a:hover {
            text-decoration: none !important;
            outline: none;
            color: #1b2655;
            font-family: var(--robotobold);
        }
    }

    .viewer-navbar-title.viewer-navbar-title-first-page {
        text-transform: capitalize;
    }

    .dialogTitleFullScr,
    .dialogTitleSmallFullScr,
    .dialogTitleMediumFullScr {
        margin-left: 2%;
        color: #1b2655;
        /* font-family: var(--base-font); */
        font-family: var(--robotobold);
        font-weight: inherit;
        display: block;
        width: 100%;
        float: left;
        line-height: 1.6;
        font-size: 20px;
        /* text-shadow: 0 0 1px rgba(90, 80, 80, .25), 0 1px 0 rgba(255, 255, 255, 0.5); */
    }

    .jre-close-btn{
        background-color: transparent;
        border: none;

    }

    .jre-close-btn:active, .jre-close-btn:focus{
        outline: none;
    }

    .jre-close {
        color: #1b2655;
        position: relative;
        top: 6px;
        right: 6px;
        /* text-shadow: 1px 1px 6px rgba(0, 10, 20, .25) !important; */
        transition-duration: 0.3s;
        cursor: pointer;

    }

    .jre-close:hover {
        text-shadow: 0 0 12px rgba(0, 0, 0, .55) !important;
        color: #fff !important;
    }


    .page-title {
        margin-left: 20px;
        text-shadow: 0 0 5px rgba(30, 40, 50, .25) !important;

    }
}
.glyphicon-remove:before{
  color: #1b2655;
}
/* .jre-player .node{
  width: 100%;
  height: 768px;

} */
