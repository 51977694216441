@import './jre-variables.css';

.diviplbottom {
        position: fixed;
        bottom: 0;
        height: 45px;
        width: 100%;
        /* background-color: var(--pitsco-active-blue); */
        background-color: #FFFFFF;
        /* color: var(--text-white); */
        color: #1b2655;
        z-index: 10005;
        /* -webkit-box-shadow: 0 -2px 6px 1px rgba(0, 3, 10, .32);
        -moz-box-shadow: 0 -2px 6px 1px rgba(0, 3, 10, .32);
        box-shadow: 0 -2px 6px 1px rgba(0, 3, 10, .32); */

      font-family: var(--robotobold);
    }

    /* Player controls - navigation at bottom in footer */

    .plbtn {
        border: 0;
        height: 31px;
        width: 28px;
        cursor: pointer;
        float: left;
        background: url('../../images/playericons.png') no-repeat scroll transparent;
    }

    .plbtn-pause {
    @extend .plbtn;
        background-position: -55px 1px;
    }

    .plbtn-cc {
    @extend .plbtn;
        background-position: -82px 1px;
    }

    .plbtn-prev {
    @extend .plbtn;
        background-position: 0 1px;
    }

    .plbtn-replay {
    @extend .plbtn;
        background-position: -27px 1px;
    }

    .plbtn-replay:hover {
    @extend .plbtn;
        background-position: -27px -29px;
    }

    .plbtn-prev:hover {
    @extend .plbtn;
        background-position: 0 -29px;
    }

    .plbtn-next {
    @extend .plbtn;
        background-position: -109px 1px;
    }

    .plbtn-cc:hover {
    @extend .plbtn;
        background-position: -82px -29px;
    }

    .plbtn-cc.active {
    @extend .plbtn;
        background-position: -82px -58px;
    }

    .plbtn-next:hover {
    @extend .plbtn;
        background-position: -109px -29px;
    }

    .plbtn-pause:hover {
    @extend .plbtn;
        background-position: -55px -29px;
    }

    .active-cc {
    @extend .plbtn;
        background-position: -82px -58px;
    }

    .playerbuttons .pl-buttoninner{
        width: 170px;
        display: block;
    }
    .playerbuttons {
        height: 30px;
        margin: 5px auto;
        width: 100%;
        padding: 0 5%;
    }

    .playerbuttons .jre-student-name{
        font-size:18px;
        font-weight: bold;
        margin-top: 5px;
    }

    .pl-buttoninner {
        width: 140px;
        margin: 0 auto;
        button {
            margin-right: 1px;
        }
    }
    .pl-buttoninner button,
    .pl-buttoninner button:active,
    .pl-buttoninner button:focus {
        outline: none;
    }

    .plbtn-play {
    @extend .plbtn;
        background-position: -136px 1px;
    }

    .plbtn-play:hover {
    @extend .plbtn;
        background-position: -136px -29px;
    }

    .plbtn-play:disabled,
    .plbtn-pause:disabled,
    .plbtn-prev:disabled,
    .plbtn-next:disabled,
    .plbtn-cc:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
.plbtn-replay{
  position: absolute;
  right: 40px;
}
.plbtn-cc{
  position: absolute;
  right: 5px;
}
