@import './jre-variables.css';
.jre-player {

    .assessment-answer-selected {
        color: var(--selected-txt-color);
    }

    .assessment-answer {
        cursor: pointer;
    }

    .assessment-nav {
        height: var(--assessment-nav-height);
    }

    .assessment-overlay-mask {
        position: absolute;
        top: 0;
        /* Bottom 100px - the bottom navigation area should not be covered with an overlay mask */
        bottom: var(--assessment-nav-height);
        /* This z-index value should be the highest relative to other values */
        z-index: 100;
        width: 100%;
    }

    .assessment-button {
        height: 65px;
        width: 125px;
        display: inline-block;
        cursor: pointer;
        border: 0;
        padding: 0;
        position: relative;
        top:-5px;
        outline:none;
    }
    .assessment-button:focus {
        outline:none;
    }

    .assessment-continue {
    @extend .assessment-button;
        background: url('../../images/modules/assess_continue.png') no-repeat;
        margin-left: 15px;
    }

    .assessment-continue:hover:not([disabled]) {
        background-position: var(--assessment-btn-hover-bg-position);
    }

    .assessment-cancel {
    @extend .assessment-button;
        background: url('../../images/modules/assess_cancel.png') no-repeat;
    }

    .assessment-cancel:disabled {
        background-position: var(--assessment-btn-disabled-bg-position);
        cursor: default;
    }

    .assessment-cancel:hover:not([disabled]) {
        background-position: var(--assessment-btn-hover-bg-position);
    }

    .assessment-bottom-container {
        bottom: 20%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
    }

    .assessment-top-container {
        top: 20%;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        width: 640px;
        word-break: break-word;
        color: var(--color-black);
    }

    .assessment-player-button {
        height: 32px;
        width: 25px;
        cursor: pointer;
        display: inline-block;
    }

    .assessment-play {
    @extend .assessment-player-button;
        background: url('../../images/modules/assess_play.png') no-repeat;
        display: inline-block;
        bottom: 25px;
        left: 10px;
    }

    .assessment-play:hover,
    .assessment-pause:hover {
        background-position: var(--assessment-player-btn-hover-bg-position);
    }

    .assessment-pause {
    @extend .assessment-player-button;
        background: url("../../images/modules/assess_pause.png") no-repeat;
        display: inline-block;
        bottom: 25px;
        left: 40px;
    }

    .assessment-done {
    @extend .assessment-button;
        background: url("../../images/modules/assess_done.png") no-repeat;
    }

    .assessment-done:disabled {
        background-position: var(--assessment-btn-disabled-bg-position);
        cursor: default;
    }

    .assessment-done:hover:not([disabled]) {
        background-position: var(--assessment-btn-hover-bg-position);
    }

    .assessment-controls-container {
        bottom: 0;
        left: 100px;
    }

    .assessment-status-message {
        bottom: 10px;
        right: -3px;
        height: 50px;
        color: #FFFFFF;
        font-size: var(--assessment-status-message-font-size);
        width: 240px;
        word-break: break-word;
        text-align: center;
    }

    .assessment-answer-message {
        bottom: 34px;
        left: 280px;
        color: #FFFFFF;
    }

    .assessment-right-image {
        width: 47%;
        display: inline-block;
        padding: 0 2% 0 1%;
        float: right;
    }

    .assessment-right-image img {
        max-width: 100%;
        height: auto;
    }

    .assessment-left-question {
        width: 46.5%;
        display: inline-block;
        padding: 0 1% 0 2.2%;
    }

    .assessment-content-container {
        top: 15%;
        width: calc(100% - 30px);
        word-break: break-word;
        text-align: left;
        margin-left:10px;
    }

    .assessment-content-container .assessment-left-question p{
        padding: 0 0 20px 0;
        font-size: 110%;
    }

    .assessment-content-container .assessment-left-question li{
        padding: 10px 0;
        font-size: 110%;
    }

    .assessment-container {
        width: 100%;
        height: 100%;
        color: #000;
    }

    .assessment-container h1{
        color: #000;
        float:left;
        font-size: 40px;
    }

    .assessment-content-container .current-user {
        position: absolute;
        top: -90px;
        right: 17%;
        display: block;
        float: right;
        font-size: 30px;
        color: var(--pitsco-blue);
        font-weight: bold;
        text-transform: uppercase;
    }
    .assessment-play:disabled,
    .assessment-pause:disabled {
        background-position: var(--assessment-player-btn-disabled-bg-position);
    }
    .question-count {
        -webkit-transform:rotate(270deg);
        -moz-transform:rotate(270deg);
        -o-transform: rotate(270deg);
        -ms-transform:rotate(270deg);
        transform: rotate(270deg);
        display: inline-block;
        position: absolute;
        text-transform: uppercase;
        bottom: 160px !important;
        left: -58px;
        font-size: 27px !important;
        letter-spacing: 0.4px;
        word-spacing:1.2px;
    }

}
.jre-player .assessment-answer-selected {
        color: #1b2655;
        font-weight: 600;
    }

.jre-player .assessment-done:disabled{
  opacity: 0.7;
}
.jre-player .assessment-play:disabled, .jre-player .assessment-pause:disabled{
    opacity: 0.7;
}
.node.assessment-container{
  width: 100%;
  height: 768px;

}
.jre-player.book-content{
  height: calc(100% - 70px) !important;
}
