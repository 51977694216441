/* ``@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:400,500,700');

@font-face {
    font-family: 'Roboto Light';
    src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed Bold';
    src: url('../fonts/RobotoCondensed-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'robotoregular';
        src: url('../fonts/roboto-regular.woff2') format('woff2'),
    url('../fonts/roboto-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
        src: url('../fonts/roboto-medium.woff2') format('woff2'),
      url('../fonts/roboto-medium.woff') format('woff');
      font-weight: normal;
      font-style: normal;
}
@font-face {
    font-family: 'robotobold';
    src: url('../fonts/roboto-bold.woff2') format('woff2'),
    url('../fonts/roboto-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

:root {

    --pitsco-black: #231f20;
    --pitsco-slate-gray: #414653;
    --pitsco-dark-cool-gray: #505666;
    --pitsco-medium-gray: #626366;
    --pitsco-medium-cool-gray: #606A77;

    --pitsco-light-gray: #1b2655;
    --pitsco-off-white: #ECEBED;
    --pitsco-white: #FCFAFA;
    --pitsco-blue-gray: #2E3648;
    --pitsco-dark-gray: #1A1D2A;
    --secondary-color: #EBE8E6;


    --pitsco-blue: #00539f;
    --pitsco-normal-blue: #70a9c1;
    --pitsco-darker-blue: #508Aaa;
    --pitsco-active-blue: #46729E;
    --pitsco-hover-blue: #9bd4ef;
    --pitsco-hover-blue-light: #b4e4ff;
    --pitsco-blue-darker: #01366c;

     --pitsco-blue-lighter: #1b2655;
    --pitsco-blue-light-lti: #97c2d6;
    --pitsco-baby-blue: #0490EE;


    --pitsco-red: #EF1E25;
    --pitsco-orange: #F86620;


    --text-black: #1f1d22;
    --text-gray: #3F4E68;
    --text-white: #FFFFFF;
    --text-disabled: #c8c6c5;
    --text-muted: #B8B6B5;
    --text-muted-dark: #7C8EAF;
    --text-muted-light: #CACED7;
    --text-danger: #EF1E25;
    --text-primary: #414653;

    --color-primary: #70a9c1;
    --color-default: #404555;
    --color-light: #dee7ec;
    --color-secondary: #F99909;
    --color-success: #66BB55;
    --color-danger: #EF1E25;
    --color-warning: #F86620;
    --color-medium: #60636C;
    --color-info: #808285;
    --color-green: #2CC24C;
    --color-gold: #F2B242;


    --secondary-font: 'Roboto', 'Roboto Light', Helvetica, Arial, "Lucida Grande", sans-serif;
    --condensed-font: 'Roboto Condensed', 'Roboto', 'Roboto Light', Helvetica, Arial, sans-serif !important;
    --base-font: 'Roboto Light', 'Roboto',  Helvetica, Arial, "Lucida Grande", sans-serif;
    --header-font: 'Roboto Condensed Bold', 'Roboto Condensed' Verdana, Arial, Helvetica, sans-serif;
    --robotoregular:"robotoregular";
    --robotomedium:"robotomedium";
    --robotobold: 'robotobold';


    --standard-padding: 10px;
    --standard-margin: 10px;


    --assessment-nav-height: 100px;
    --assessment-status-message-font-size: 18px;
    --assessment-player-btn-hover-bg-position: 0 -96px;
    --assessment-player-btn-disabled-bg-position: 0 -128px;
    --assessment-btn-disabled-bg-position: 0 -260px;
    --assessment-btn-hover-bg-position: 0 -195px;


    --standard-border-width: 1px;
    --standard-border-radius: 4px;
    --standard-border-style: solid;
    --standard-border-color: var(--pitsco-light-gray);
    --te-border-width: 4px;


    --caption-padding: 5px;
    --caption-container-margin: 10px;
    --caption-border-width: 1px;
    --caption-border-radius: 4px;
    --caption-border-style: solid;
    --caption-border-color: var(--color-light);


    --color-white: var(--text-white);
    --selected-txt-color: var(--pitsco-red);
    --notification-bg-color: var(--color-secondary);
    --caption-bg-color: var(--pitsco-light-gray);
    --section-sidebar-select-bg-color: var(--pitsco-blue-lighter);
    --section-sidebar-text-color: var(--pitsco-red);
    --color-black: #000;
} */

/* @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700|Roboto:400,500,700'); */

@font-face {
    font-family: 'Roboto Light';
    src: url('../fonts/Roboto-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Roboto Condensed Bold';
    src: url('../fonts/RobotoCondensed-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'robotoregular';
        src: url('../fonts/roboto-regular.woff2') format('woff2'),
    url('../fonts/roboto-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'robotomedium';
        src: url('../fonts/roboto-medium.woff2') format('woff2'),
      url('../fonts/roboto-medium.woff') format('woff');
      font-weight: normal;
      font-style: normal;
}
@font-face {
    font-family: 'robotobold';
    src: url('../fonts/roboto-bold.woff2') format('woff2'),
    url('../fonts/roboto-bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

:root {
    /*Neutral Shades*/
    /* --pitsco-black: #231f20;
    --pitsco-slate-gray: #414653;
    --pitsco-dark-cool-gray: #505666;
    --pitsco-medium-gray: #626366;
    --pitsco-medium-cool-gray: #606A77; */
    /* --pitsco-light-gray: #e4e2e2; */
    /* --pitsco-light-gray: #1b2655;
    --pitsco-off-white: #ECEBED;
    --pitsco-white: #FCFAFA;
    --pitsco-blue-gray: #2E3648;
    --pitsco-dark-gray: #1A1D2A;
    --secondary-color: #EBE8E6; */

    /*Blue Shades*/
    /* --pitsco-blue: #00539f;
    --pitsco-normal-blue: #70a9c1;
    --pitsco-darker-blue: #508Aaa;
    --pitsco-active-blue: #46729E;
    --pitsco-hover-blue: #9bd4ef;
    --pitsco-hover-blue-light: #b4e4ff;
    --pitsco-blue-darker: #01366c; */
    /* --pitsco-blue-lighter: #0870D8; */
     /* --pitsco-blue-lighter: #1b2655; */
    --pitsco-blue-light-lti: #97c2d6;
    --pitsco-baby-blue: #0490EE;

    /*Accent Shades*/
    --pitsco-red: #EF1E25;
    --pitsco-orange: #F86620;

    /*Text Shades*/
    --text-black: #1f1d22;
    --text-gray: #3F4E68;
    --text-white: #FFFFFF;
    --text-disabled: #c8c6c5;
    --text-muted: #B8B6B5;
    --text-muted-dark: #7C8EAF;
    --text-muted-light: #CACED7;
    --text-danger: #EF1E25;
    /* --text-primary: #414653; */

    --color-primary: #70a9c1;
    --color-default: #404555;
    --color-light: #dee7ec;
    --color-secondary: #F99909;
    --color-success: #66BB55;
    --color-danger: #EF1E25;
    --color-warning: #F86620;
    --color-medium: #60636C;
    --color-info: #808285;
    --color-green: #2CC24C;
    --color-gold: #F2B242;

    /*Font*/
    /* --secondary-font: 'Roboto', 'Roboto Light', Helvetica, Arial, "Lucida Grande", sans-serif;
    --condensed-font: 'Roboto Condensed', 'Roboto', 'Roboto Light', Helvetica, Arial, sans-serif !important;
    --base-font: 'Roboto Light', 'Roboto',  Helvetica, Arial, "Lucida Grande", sans-serif;
    --header-font: 'Roboto Condensed Bold', 'Roboto Condensed' Verdana, Arial, Helvetica, sans-serif;
    --robotoregular:"robotoregular";
    --robotomedium:"robotomedium";
    --robotobold: 'robotobold'; */

    /* JRE Layout Variables */
    /* --standard-padding: 10px;
    --standard-margin: 10px; */

    /* Variables for Assessments */
    /* --assessment-nav-height: 100px;
    --assessment-status-message-font-size: 18px;
    --assessment-player-btn-hover-bg-position: 0 -96px;
    --assessment-player-btn-disabled-bg-position: 0 -128px;
    --assessment-btn-disabled-bg-position: 0 -260px;
    --assessment-btn-hover-bg-position: 0 -195px; */

    /* Variables for Borders */
    /* --standard-border-width: 1px;
    --standard-border-radius: 4px;
    --standard-border-style: solid;
    --standard-border-color: var(--pitsco-light-gray);
    --te-border-width: 4px; */

    /* Variables for Captions */
    /* --caption-padding: 5px;
    --caption-container-margin: 10px;
    --caption-border-width: 1px;
    --caption-border-radius: 4px;
    --caption-border-style: solid;
    --caption-border-color: var(--color-light); */

    /* Variables for JRE Colors */
    --color-white: var(--text-white);
    --selected-txt-color: var(--pitsco-red);
    /* --notification-bg-color: var(--color-secondary);
    --caption-bg-color: var(--pitsco-light-gray); */
    /* --section-sidebar-select-bg-color: var(--pitsco-blue-lighter); */
    --section-sidebar-text-color: var(--pitsco-red);
    --color-black: #000;
}
