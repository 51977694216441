@import './jre-variables.css';
@import './jre-footer.css';
@import './general.css';
@import './jre-navbar.css';
@import './jre-sidebar.css';
@import './assessments.css';

/* - for loading show */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loader {
  border: 12px solid #f3f3f3 !important;
  border-top: 8px solid #1b2655 !important;
  border-radius: 50%;
  width: 80px !important;
  height: 80px !important;
  animation: spin 1s linear infinite;
  position: absolute;
  text-align: center;
  top:40%;
  left: 34%;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@keyframes spin {
 0% {
   transform: rotate(0deg);
 }
 100% {
   transform: rotate(360deg);
 }
}
