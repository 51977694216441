@import './jre-variables.css';

.jre-player {


    .plright {
        width: 210px;
        height: 100%;
        position: fixed;
        float: left;
        background: var(--pitsco-light-gray);
        border-left: 1px solid var(--text-muted);
        display: inline-block;
        max-height: calc(100vh - 95px);
        overflow-y: scroll;
        z-index: 1002;
        right: 0;
        top: 50px;
        box-shadow: -2px 0 8px 0 rgba(0,0,0,0.32);
    }

    .pagelist {
        height: 100%;
        width: 100%;
        display: inline-block;
        margin: 0;
        margin-top: 5px;
        text-align: left;
        overflow: hidden;
        font-size: 13px;
        padding: 0 0 32px 4px;
        list-style: none outside none;
    }

    .book-sidebar {

        a {
            font-family: var(--robotoregular);
            font-size: 14px;
            list-style: none outside none;
            margin: 0;
            word-wrap: break-word;
            cursor: pointer;
            /* color: var(--pitsco-blue-lighter); */
            color: #1b2655;
            font-weight: 400;
            line-height: 1.8;
            text-decoration: none;
            padding: 10px 2px 10px 10px;
        }

        a.dim {
            /* color: var(--pitsco-medium-gray); */
            color: #1b2655;
            opacity: 0.6;
            cursor: not-allowed;
        }

        .pagelist a.active {
            /* color: var(--section-sidebar-text-color); */
            color: #1b2655;
            font-family: var(--robotomedium);
            font-weight: 600;

        }

        button {
            font-family: var(--header-font);
            font-size: 14px;
            list-style: none outside none;
            margin: 0;
            word-wrap: break-word;
            cursor: pointer;
            color: var(--pitsco-blue-lighter);
            font-weight: 400;
            line-height: 1.8;
            text-decoration: none;
            background-color: transparent;
            border: none;
            padding: 0 0 0 10px;
            letter-spacing: -0.3px;
        }

        button:active, button:focus {
            outline: none;
        }

        button.dim {
            color: var(--pitsco-medium-gray);
            opacity: 0.8;
            cursor: not-allowed;
        }

        .pagelist button.active {
            color: var(--section-sidebar-text-color);
        }

        .section-select {
            background-color: var(--section-sidebar-select-bg-color);
            width: 100%;
            overflow:auto;
            font-family: var(--header-font);
            display: block; padding: 10px 70px 10px 13px !important; max-width: 100%;
            height: auto !important; border: 1px solid var(--secondary-color); border-radius: 3px;
            font-weight: 700;
            color: #1b2655;
             font-size: 14px; line-height: 16px;
            &:focus{
                outline:none;
            }
                &> option{
                    margin:3px;
                    padding:6px 8px;
                    text-shadow:none;
                    color: var(--pitsco-blue-darker);
                    background:var(--secondary-color);
                    border-radius:3px;
                    cursor:pointer;
                }




        }

    }

.bane {
    text-align: left;
}
    .bane a {
        text-align: left;
        font-weight: bold;
        font-size: 15px;
        padding: 4px 0 4px 2px;
        display: inline-block;
    }


}

.moduleButtonRight {
    background: url('../../images/modules/arrowpack.png') -10px -10px no-repeat;
    width: 20px;
    height: 150px;
    position: absolute;
    margin-left: 0;
    right: -2px;
    cursor: pointer;
    top: calc(50vh - 150px);
    z-index: 1005;
}

.moduleButtonRight:hover {
    background: url('../../images/modules/arrowpack.png') -50px -10px no-repeat;
}

.moduleButtonLeft{
    background: url('../../images/modules/arrowpack.png') -90px -10px no-repeat;
    width: 20px;
    height: 150px;
    position: absolute;
    margin-left: 0;
    right: -1px;
    cursor: pointer;
    top: calc(50vh - 150px);
    z-index: 1005;
}

.moduleButtonLeft:hover {
    background: url('../../images/modules/arrowpack.png') -130px -10px no-repeat;
}



.extendedButtonRight {
    background: url('../../images/modules/arrowpack.png') -10px -10px no-repeat;
    width: 20px;
    height: 150px;
    position: absolute;
    margin-left: 0;
    left: -2px;
    cursor: pointer;
    top: calc(50vh - 150px);
    z-index: 1005;
}

.extendedButtonRight:hover {
    background: url('../../images/modules/arrowpack.png') -50px -10px no-repeat;
}

.extendedButtonLeft{
    background: url('../../images/modules/arrowpack.png') -90px -10px no-repeat;
    width: 20px;
    height: 150px;
    position: absolute;
    margin-left: 0;
    left: -1px;
    cursor: pointer;
    top: calc(50vh - 150px);
    z-index: 1005;
}

.extendedButtonLeft:hover {
    background: url('../../images/modules/arrowpack.png') -130px -10px no-repeat;
}
